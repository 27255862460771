import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { API_RESOURCE_PRODUCT, API_RESOURCE_PRODUCT_CATEGORIES } from 'Consts/apiResources';
import { ADMIN_PRODUCTS_MANAGE } from 'Consts/routes';
import { 
    PRODUCT_VAT_PERCENTS, 
    PRODUCT_DURATION_TYPES,
    PRODUCT_VISIBILITIES,
    PRODUCT_VISIBILITY_SHOP_CREATOR,
    PRODUCT_TYPES,
    PRODUCT_TYPE_PLANNABLE,
} from 'Consts/products';
import { LOCATION_TYPES } from 'Consts/locations';
import { ENDPOINT_ADMIN_FILE_PRESIGN } from 'Consts/api';

import { withVariables } from 'Utils/string';
import { fromSelectObject } from 'Utils/object';

import StyledComponent from 'Components/core/StyledComponent';
import Spinner from 'Components/layoutAdmin/Spinner';
import ElementEditor from 'Components/layoutAdmin/panel/ElementEditor';

export default class PanelProductsEditor extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            create: PropTypes.func.isRequired,
            update: PropTypes.func.isRequired,
            setEnabledStatus: PropTypes.func.isRequired,
            listCategories: PropTypes.func.isRequired,
            presignFile: PropTypes.func.isRequired,
        }).isRequired,
        data: PropTypes.object,
    };
    static defaultProps = {
        data: null,
    };

    state = {
        formState: {},
        sortByQuantity: true,
        pricingMatrixElementExists: false,
    };

    componentDidMount = () => {
        const { data } = this.props;

        if (data) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    componentDidUpdate = prevProps => {
        const { data } = this.props;

        if (data && JSON.stringify(data) !== JSON.stringify(prevProps.data)) {
            this.setState(prevState => ({
                formState: {
                    ...prevState.formState,
                    ...this.dataToFormState(data),
                },
            }));
        }
    }

    dataToFormState = data => ({
        ...data,
        categoryId: data.category
            ? { value: data.category.id, label: data.category.name }
            : null,
    })

    formStateToData = formState => ({
        ...formState,
        categoryId: fromSelectObject(formState.categoryId),
        vatPercent: fromSelectObject(formState.vatPercent),
        durationType: fromSelectObject(formState.durationType) || undefined,
        visibility: fromSelectObject(formState.visibility),
        isPlannable: fromSelectObject(formState.isPlannable),
        locationType: fromSelectObject(formState.locationType) || undefined,
        displayDurationType: fromSelectObject(formState.displayDurationType) || undefined,
        secondLocationType: fromSelectObject(formState.secondLocationType) || undefined,
        thirdLocationType: fromSelectObject(formState.thirdLocationType) || undefined,
        imageId: formState.image ? formState.image.id : undefined,
    })

    onSubmit = formState => {
        const { data } = this.props;

        return data && data.id
            ? this.onUpdate(formState)
            : this.onCreate(formState);
    }

    onCreate = formState => {
        const { actions, history } = this.props;

        return actions.create(this.formStateToData(formState))
            .then(response => {
                history.push(
                    withVariables(
                        ADMIN_PRODUCTS_MANAGE.path,
                        { id: response.payload[API_RESOURCE_PRODUCT].id }
                    )
                );
            });
    }
    onUpdate = formState => {
        const { data, actions } = this.props;

        return actions.update({
            ...this.formStateToData(formState),
            id: data.id,
        });
    }

    validatePricingMatrix = (newPricingMatrix) => {
        for(let index in newPricingMatrix) {
            const pricingMatrix = newPricingMatrix[index];
            
            const foundIndex =  newPricingMatrix.findIndex(item => {
                return item.quantity === pricingMatrix.quantity && item.usersCount === pricingMatrix.usersCount;
            });
    
            if(foundIndex != -1 && foundIndex != index) {
                toast.error('Element o podanych wartościach już występuje w cenniku');
                return false;
            }
        }

        return true;
    }

    render() {
        const { data, location, history, actions } = this.props;
        const { formState, sortByQuantity } = this.state;

        if (!formState) {
            return (<Spinner />);
        }

        return (
            <StyledComponent
                className="panel-products-editor"
                styles={require('./styles')}
            >
                <ElementEditor
                    location={location}
                    history={history}
                    details={[{
                        visible: Boolean(data && data.id),
                        label: 'id',
                        value: formState.id,
                    }]}
                    controls={[{
                        visible: Boolean(data && data.id && data.enabled),
                        title: 'Wyłącz produkt',
                        subtitle: 'Produkt nie będzie dostępny przy tworzeniu ofert',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: false }),
                            children: 'Wyłącz',
                        },
                    }, {
                        visible: Boolean(data && data.id && !data.enabled),
                        title: 'Włącz produkt',
                        subtitle: 'Produkt będzie dostępny przy tworzeniu ofert',
                        buttonProps: {
                            onClick: () => actions.setEnabledStatus({ id: data && data.id, enabled: true }),
                            children: 'Włącz',
                        },
                    }]}
                    forms={[{
                        title: 'Dane',
                        validation: () => this.validatePricingMatrix(formState.pricingMatrix),
                        name: 'editor',
                        submitAction: this.onSubmit,
                        data: formState,
                        onStateChange: formState => {
                            this.setState({
                                formState,
                            });
                        },
                        elements: [{
                            type: 'input',
                            name: 'name',
                            label: 'Nazwa',
                        }, {
                            type: 'input',
                            name: 'displayName',
                            label: 'Nazwa wyświetlana klientowi',
                        }, {
                            type: 'textarea',
                            name: 'description',
                            label: 'Opis',
                        }, {
                            type: 'select',
                            name: 'categoryId',
                            label: 'Kategoria',
                            inputProps: {
                                disabled: false,
                                dynamic: true,
                                onLoad: query => actions.listCategories({ search: query }),
                                onMapResponse: response => response.payload[API_RESOURCE_PRODUCT_CATEGORIES].elements,
                                onMapOption: element => ({
                                    value: element.id,
                                    label: element.name,
                                }),
                            },
                        }, {
                            type: 'input',
                            name: 'priceGross',
                            label: 'Cena brutto',
                            inputProps: {
                                type: 'number',
                            },
                        }, {
                            type: 'select',
                            name: 'vatPercent',
                            label: 'Domyślna stawka VAT',
                            options: PRODUCT_VAT_PERCENTS.map(vatPercent => ({
                                value: vatPercent.key,
                                label: vatPercent.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'isPlannable',
                            label: 'Typ produktu',
                            options: PRODUCT_TYPES.map(isPlannable => ({
                                value: isPlannable.key,
                                label: isPlannable.label,
                            })),
                        }, {
                            type: 'input',
                            name: 'durationValue',
                            label: 'Wartość trwania',
                            inputProps: {
                                type: 'number',
                            },
                            isVisible: fromSelectObject(formState.isPlannable) === PRODUCT_TYPE_PLANNABLE,
                        }, {
                            type: 'select',
                            name: 'durationType',
                            label: 'Typ trwania',
                            options: PRODUCT_DURATION_TYPES.map(durationType => ({
                                value: durationType.key,
                                label: durationType.label,
                            })),
                            isVisible: fromSelectObject(formState.isPlannable) === PRODUCT_TYPE_PLANNABLE,
                        }, {
                            type: 'input',
                            name: 'displayDurationValue',
                            label: 'Wyświetlana wartość trwania',
                            inputProps: {
                                type: 'number',
                            },
                            isVisible: fromSelectObject(formState.isPlannable) === PRODUCT_TYPE_PLANNABLE,
                        }, {
                            type: 'select',
                            name: 'displayDurationType',
                            label: 'Typ wyświetlanej wartości trwania',
                            options: PRODUCT_DURATION_TYPES.map(durationType => ({
                                value: durationType.key,
                                label: durationType.label,
                            })),
                            isVisible: fromSelectObject(formState.isPlannable) === PRODUCT_TYPE_PLANNABLE,
                        }, {
                            type: 'select',
                            name: 'visibility',
                            label: 'Widoczność',
                            options: PRODUCT_VISIBILITIES.map(visibility => ({
                                value: visibility.key,
                                label: visibility.label,
                            })),
                        }, {
                            type: 'select',
                            name: 'locationType',
                            label: 'Typ lokalizacji',
                            options: LOCATION_TYPES.map(locationType => ({
                                value: locationType.key,
                                label: locationType.label,
                            })),
                            isVisible: fromSelectObject(formState.isPlannable) === PRODUCT_TYPE_PLANNABLE,   
                        }, {
                            type: 'select',
                            name: 'secondLocationType',
                            label: 'Alternatywny typ lokacji',
                            options: LOCATION_TYPES.map(locationType => ({
                                value: locationType.key,
                                label: locationType.label,
                            })),
                            isVisible: Boolean(formState.locationType),
                        }, {
                            type: 'select',
                            name: 'thirdLocationType',
                            label: 'Drugi alternatywny typ lokacji',
                            options: LOCATION_TYPES.map(locationType => ({
                                value: locationType.key,
                                label: locationType.label,
                            })),
                            isVisible: Boolean(formState.locationType),
                        }, {
                            type: 's3FileUpload',
                            name: 'image',
                            label: 'Zdjęcie',
                            inputProps: {
                                action: actions.presignFile,
                                s3Config: {
                                    presignPath: ENDPOINT_ADMIN_FILE_PRESIGN
                                        .replace('{type}', 'product'),
                                },
                            },
                        }, {
                            type: 'inputsList',
                            name: 'pricingMatrix',
                            label: 'Cennik',
                            inputProps: {
                                config: [{
                                    name: 'quantity',
                                    label: 'Ilość',
                                    placeholder: 'Ilość',
                                    type: 'number',
                                    required: true,
                                }, {
                                    name: 'valueGross',
                                    label: 'Cena za 1 szt.',
                                    placeholder: 'Cena za 1 szt.',
                                    type: 'number',
                                    required: true,
                                }, {
                                    name: 'usersCount',
                                    label: 'Ilość osób',
                                    placeholder: 'Ilość osób',
                                    type: 'number',
                                    required: true,
                                }],
                                link: {
                                    enabled: true,
                                    label: sortByQuantity ? 'Sortuj wg. ilości' : 'Sortuj wg. ilości użytkowników',
                                    onClick: () => {
                                        this.setState({
                                            sortByQuantity: !sortByQuantity,
                                        });
                                    formState?.pricingMatrix?.sort(function(a, b) {
                                        var keyA = sortByQuantity ? a.quantity : a.usersCount;
                                        var keyB = sortByQuantity ? b.quantity : b.usersCount;
                            
                                        if (keyA < keyB) return -1;
                                        if (keyA > keyB) return 1;
                                        return 0;
                                    });},
                                },
                            },
                            isVisible: fromSelectObject(formState.visibility) === PRODUCT_VISIBILITY_SHOP_CREATOR,   
                        }, {
                            type: 'input',
                            name: 'order',
                            label: 'Kolejność wyświetlania',
                            inputProps: {
                                type: 'number',
                            },
                        }],
                    }]}
                />
            </StyledComponent>
        );
    }
}

